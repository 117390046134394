import React from "react"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import classes from "./index.module.css"

const ExploreOverlay = ({ show, setVisible }) => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "frank-logo.png" }) {
        childImageSharp {
          fixed(height: 35) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      landing: file(relativePath: { eq: "landing-page.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const onExplore = event => {
    setVisible(false)
  }
  const backImgClassName = [
    classes.container,
    show ? classes.in : classes.out,
  ].join(" ")
  return (
    <div className={backImgClassName}>
      <BackgroundImage
        className={backImgClassName}
        fluid={[
          data.landing.childImageSharp.fluid,
          `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3))`,
        ].reverse()}
      >
        <div className={classes.logoContainer}>
          <Img
            fixed={data.logo.childImageSharp.fixed}
            className={classes.logo}
            alt="logo-back-to-top"
          />
        </div>
        <div className={classes.contentContainer}>
          <div className={classes.subContent1} />
          <div className={classes.subContent}>
            <span className={classes.contentTitle}>
              {"Each Home is a unique masterpiece.".toUpperCase()}
            </span>
            <button className={classes.exploreButton} onClick={onExplore}>
              {`Explore Frank`.toUpperCase()}
            </button>
          </div>
        </div>
      </BackgroundImage>
    </div>
  )
}

export default ExploreOverlay
