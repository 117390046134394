import PropTypes from "prop-types"
import React, { useState } from "react"
import classes from "./Header.module.css"
import ContentContainer from "../../../ContentContainer/ContentContainer"
import Logo from "./Logo/Logo"
import MobileNav from "./Navigation/MobileNav/MobileNav"
import MobileNavMenu from "./Navigation/MobileNav/MobileNavMenu/MobileNavMenu"
import DeskTopNavMenu from "./Navigation/DesktopNav/DesktopNavMenu"

const Header = ({ siteTitle }) => {
  const [modalOpen, setModalOpen] = useState(false)

  const sideDrawerToggle = () => {
    setModalOpen(prevState => !prevState)
  }

  return (
    <React.Fragment>
      <MobileNavMenu modalOpen={modalOpen} onClick={sideDrawerToggle} />
      <DeskTopNavMenu onClick={sideDrawerToggle} modalOpen={modalOpen} />
      <header className={classes.headerRoot}>
        <ContentContainer>
          <div className={classes.headerWrapper}>
            <Logo siteTitle={siteTitle} />
            <MobileNav modalOpen={modalOpen} onClick={sideDrawerToggle} />
          </div>
        </ContentContainer>
      </header>
    </React.Fragment>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: `Frank.`,
}

export default Header
