import React from "react"
import classes from "./Hamburger.module.css"

const Hamburger = props => {
  const { modalOpen, onClick } = props
  return (
    <div className={classes.hamburgerContainer}>
      <button
        onClick={onClick}
        className={[
          classes.hamburger,
          classes.hamburgerMinus,
          modalOpen ? classes.isActive : null,
        ].join(" ")}
        type="button"
      >
        <span className={classes.hamburgerBox}>
          <span className={classes.hamburgerInner} />
        </span>
      </button>
    </div>
  )
}

export default Hamburger
