import React from "react"
import { Link } from "gatsby"
import classes from "./index.module.css"
import { MenuLinks } from "../../MenuLinks"

const DeskTopNavMenu = props => {
  const { modalOpen, onClick } = props
  return (
    <aside
      className={[
        classes.mobileNavMenuRoot,
        modalOpen ? classes.in : classes.out,
      ].join(" ")}
    >
      <div className={classes.deskTopNavMenu}>
        <nav className={[classes.nav].join(" ")}>
          {MenuLinks.map((link, index) => (
            <div key={link.text} className={classes.linkWrapper}>
              <Link
                to={index === 0 ? `/${link.text}` : `#${link.text}`}
                className={classes.link}
                onClick={onClick}
                state={{ isShownExplore: false }}
              >
                {link.text.toUpperCase()}
              </Link>
            </div>
          ))}
        </nav>
      </div>
    </aside>
  )
}

export default DeskTopNavMenu
