import React from "react"
import classes from "./Logo.module.css"
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"

const Logo = () => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "frank-logo.png" }) {
        childImageSharp {
          fixed(height: 35) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)
  return (
    <div className={classes.logoContainer}>
      <Link
        to="/#top"
        className={classes.logoLink}
        state={{ isShownExplore: false }}
      >
        <Img
          fixed={data.logo.childImageSharp.fixed}
          className={classes.logo}
          alt="logo-back-to-top"
        />
      </Link>
    </div>
  )
}

export default Logo
