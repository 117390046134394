import React from "react"
import classes from "./Footer.module.css"
import ContentContainer from "../../../ContentContainer/ContentContainer"
import { FaPinterestP, FaInstagram } from "react-icons/fa"

const Footer = () => {
  return (
    <footer className={classes.footerRoot}>
      <ContentContainer>
        <div className={classes.footerFlexWrapper}>
          <div className={classes.addressContainer}>
            <p className={classes.footerText}>
              3197 Airport Loop Dr Ste E&nbsp;
            </p>
            <p className={classes.footerText}>Costa Mesa, CA 92626</p>
          </div>
          <div className={classes.emailContainer}>
            <p className={classes.footerText}>
              Email:{" "}
              <a
                href="mailto:info@frankberrydesign.com"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="email"
              >
                info@frankberrydesigns.com
              </a>
            </p>
            <div className={classes.iconContainer}>
              <a
                href="https://www.pinterest.com/fbdesigns/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="pinterest"
              >
                <FaPinterestP className={classes.icon} />
              </a>
              <a
                href="https://www.instagram.com/werfrank/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="instagram"
              >
                <FaInstagram className={classes.icon} />
              </a>
            </div>
          </div>
        </div>
      </ContentContainer>
    </footer>
  )
}

export default Footer
