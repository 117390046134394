import React from "react"
import classes from "./MobileNav.module.css"
import Hamburger from "./Hamburger/Hamburger"

const MobileNav = props => {
  const { modalOpen, onClick } = props

  return (
    <React.Fragment>
      <div className={classes.mobileNavRoot}>
        <Hamburger modalOpen={modalOpen} onClick={onClick} />
      </div>
    </React.Fragment>
  )
}

export default MobileNav
