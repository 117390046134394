import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./components/Header/Header"
import ExploreOverlay from "../ExploreOverlay"

import "./Layout.css"
import Footer from "./components/Footer/Footer"

const Layout = ({ children, location }) => {
  if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]', {
      speed: 1000,
      offset: 84,
    })
  }

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const [isShowExploreOverlay, setVisibleExploreOverlay] = useState(true)

  useEffect(() => {
    if (location.state?.isShownExplore === undefined) {
      setVisibleExploreOverlay(true)
    } else {
      setVisibleExploreOverlay(location.state?.isShownExplore)
    }
  }, [location])

  return (
    <React.Fragment>
      <ExploreOverlay
        show={isShowExploreOverlay}
        setVisible={setVisibleExploreOverlay}
      />
      <Header siteTitle={data.site.siteMetadata.title} />
      {!isShowExploreOverlay && <main>{children}</main>}
      <Footer />
    </React.Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
