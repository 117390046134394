import React from "react"
import classes from "./MobileNavMenu.module.css"
import { MenuLinks } from "../../MenuLinks"
import { Link } from "gatsby"
import Footer from "../../../../Footer/Footer"

const MobileNavMenu = props => {
  const { modalOpen, onClick } = props
  return (
    <aside
      className={[
        classes.mobileNavMenuRoot,
        modalOpen ? classes.in : classes.out,
      ].join(" ")}
    >
      <nav
        className={[classes.nav, modalOpen ? classes.in : classes.out].join(
          " "
        )}
      >
        {MenuLinks.map((link, index) => (
          <div key={link.text} className={classes.linkWrapper}>
            <Link
              to={index === 0 ? `/${link.text}` : `#${link.text}`}
              className={classes.link}
              onClick={onClick}
              state={{ isShownExplore: false }}
            >
              {link.text.toUpperCase()}
            </Link>
          </div>
        ))}
      </nav>
      <div className={classes.footer}>
        <Footer />
      </div>
    </aside>
  )
}

export default MobileNavMenu
